.gg {
  width: 100%;
  height: 100vh;
}
.carousel-caption {
  position: absolute;
  top: 35%;
  padding-top: 1.25rem !important;
  padding-bottom: 10rem !important;
  color: #fff;
  text-align: start;
  font-size: 2.75rem !important;
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next-icon {
  display: none !important;
}
.carousel-caption {
  position: absolute !important;
  right: 0 !important;
  bottom: 1.1rem !important;
  left: 7% !important;
  text-align: left !important;
  top: 21rem !important;
}

.carousel-caption h1 {
  color: #fff;
  font-size: 40px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
}
.carousel-indicators {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 1rem !important;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators > button {
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}
.active {
  position: relative !important;
  height: 100% !important;
}
.marq {
  width: 100%;
  height: 125%;
}
.banner_btn {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  background-color: #ff6700;
  border: 3px solid #ff6700;
  border-radius: 40px;
  padding: 10px 30px;
  transition: 0.2s all;
}
.banner_btn:hover {
  color: #ff6700;
  background-color: #fff;
}
.active {
  transform: translate3d(0, 0, 0);
  transition-property: opacity;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  -moz-transition: -moz-transform 0.6s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}

.overlay2:after {
  content: "";
  position: absolute !important;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#374050),
    to(#798696)
  );
  background-image: linear-gradient(to bottom, #374050 0%, #798696 100%);
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .gg {
    height: 40vh;
    width: 100%;
  }
  .carousel-caption h1{
    font-size: 20px;
  }
}
