#rs-header {
  background-color: transparent !important;
}
.breadcrumbs-img {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.rs-footer .footer-bottom .copyright p {
  font-size: 12px !important;
  text-align: center !important;
}
.view_btn {
  text-align: end;
  padding-top: 35px;
}
.view_button {
  font-size: 18px;
  padding: 10px 20px;
  border: 2px solid #ff6700;
  border-radius: 50px;
  background-color: #ff6700;
  color: #fff;
}
.view_button:hover {
  background-color: #fff;
  color: #ff6700;
}
.apply_btn {
  text-align: end;
  padding-top: 50px;
}
.apply_button {
  font-size: 12px;
  height: 30px;
  width: 100px;
  color: #fff;
  border: 1px solid linear-gradient(#3f88b6, #2b5686) !important;
  border-radius: 50px;
  background: linear-gradient(#3f88b6, #2b5686) !important;
}
.btn-send {
  background: linear-gradient(#3f88b6, #2b5686) !important;
  color: white !important;
}
.form-select {
  border: none !important;
  border-radius: none !important;
  height: 45px;
}
.breadcrumb {
  height: 250px;
  background-size: cover;
  background-position: top;
  color: white;
  text-align: center;
  padding: 10px;
}
.v-btn {
  height: 45px;
  width: 120px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid #ff6700;
  background-color: #fff;
  color: #222;
}
.v-btn:hover {
  background-color: #ff6700;
  color: #fff;
}
.media {
  display: flex;
  justify-content: center;
}
.media li {
  margin: 0 10px;
  font-size: 25px;
  padding: 10px;
  border-radius: 50%;
}
.media li .fa {
  color: #fff;
}
.media li .fa:hover {
  color: blue;
}

.media li:nth-child(1) {
  background-color: #0077b5;
}
.media li:nth-child(2) {
  background-color: #4867aa;
}
.media li:nth-child(3) {
  background-color: #405de6;
}
.media li:nth-child(4) {
  background-color: #1bd741;
}
.media li:nth-child(5) {
  background-color: #0077b5;
}
